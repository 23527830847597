import type { GoogleMapBounds, GoogleMapLatLng } from '@/features/map/google-map/google-map-types';
import type { IMapLatLngLiteral } from '@/features/map/map-data-v4/map-types';

export type BoundariesType = {
    fromLatitude: string;
    fromLongitude: string;
    toLatitude: string;
    toLongitude: string;
    zoom: string;
};

export const getBoundariesObject = (boundaries: GoogleMapBounds, zoom: number): BoundariesType => {
    return {
        fromLatitude: boundaries.getSouthWest().lat().toString(),
        fromLongitude: boundaries.getSouthWest().lng().toString(),
        toLatitude: boundaries.getNorthEast().lat().toString(),
        toLongitude: boundaries.getNorthEast().lng().toString(),
        zoom: zoom.toString(),
    };
};

export const getMapLatLngLiteral = (center?: GoogleMapLatLng): IMapLatLngLiteral => {
    return {
        latitude: parseFloat((center?.lat() ?? 0).toFixed(10)),
        longitude: parseFloat((center?.lng() ?? 0).toFixed(10)),
    };
};
