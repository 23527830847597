import type { MapDataV4_jsonld_page_read } from '@/features/map/map-data-v4/map-data-v4-type';

import React from 'react';

import useComponentDataByIri from '@/core/features/a-component/hooks/use-component-data-by-iri';
import { updateComponentIriParam } from '@/core/features/a-component/services/component-service';
import useDynamicPageComponentByType from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-component-by-type';
import { useClientUrl } from '@/core/features/app/app-atoms';
import { historyReplaceDebounced } from '@/core/features/router/history';
import { atom, useAtom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';
import { getURL } from '@/core/utils/url';
import { logMap } from '@/features/map/map-container/map-logger';
import { useAutoLoadMoreSetting } from '@/features/map/map-data-v4/auto-load-more-button-state';
import { type BoundariesType } from '@/features/map/map-data-v4/map-data-v4-service';

export const mapBoundariesAtom = atom<BoundariesType | null>(null);
export const setMapBoundariesAtom = atom(null, (get, set, boundaries: BoundariesType) => {
    const shouldInitMapBoundariesForComponents = get(shouldInitMapBoundariesForComponentsAtom);
    const shouldBlockInitMapBoundariesForComponents = get(shouldBlockInitMapBoundariesForComponentsAtom);
    logMap('setMapBoundariesAtom', {
        boundaries,
        shouldBlockInitMapBoundariesForComponents,
        shouldInitMapBoundariesForComponents,
    });
    if (shouldInitMapBoundariesForComponents && !shouldBlockInitMapBoundariesForComponents) {
        logMap('setMapBoundariesForComponentsAtom', { boundaries });
        set(mapBoundariesForComponentsAtom, boundaries);
    }

    set(shouldInitMapBoundariesForComponentsAtom, false);
    set(shouldBlockInitMapBoundariesForComponentsAtom, false);

    set(mapBoundariesAtom, boundaries);
});

export const mapBoundariesForComponentsAtom = atom<BoundariesType | null>(null);
export const shouldInitMapBoundariesForComponentsAtom = atom(false);
export const shouldBlockInitMapBoundariesForComponentsAtom = atom(false);

export const mapDataV4DefaultAtom = atom<MapDataV4_jsonld_page_read | null>(null);
export const mapDataV4ByBoundariesAtom = atom<MapDataV4_jsonld_page_read | null>(null);
export const mapDataV4ByPoiAtom = atom<MapDataV4_jsonld_page_read | null>(null);
const mapDataV4BySpotAtom = atom<MapDataV4_jsonld_page_read | null>(null);

export const isMapDataV4DefaultLoadingAtom = atom(false);
export const isMapDataV4ByBoundariesLoadingAtom = atom(false);
export const isMapDataV4BySpotLoadingAtom = atom(false);

export const mapDataV4EntryTargetAtom = atom<null | string>(null);
export const useSetMapDataV4EntryTarget = () => useSetAtom(mapDataV4EntryTargetAtom);

const isBoundariesLivePricesLoadingAtom = atom(false);

const isMapPoiLivePricesLoadingAtom = atom(false);
export const useMapPoiLivePricesLoading = () => useAtomValue(isMapPoiLivePricesLoadingAtom);

const areMapPricesLoadingAtom = atom((get) => {
    return get(isBoundariesLivePricesLoadingAtom) || get(isMapPoiLivePricesLoadingAtom);
});
export const useMapPricesLoading = () => useAtomValue(areMapPricesLoadingAtom);

const isLoadMoreButtonLoadingAtom = atom(false);
export const useLoadMoreButtonLoading = () => useAtomValue(isLoadMoreButtonLoadingAtom);

const boundariesTargetAtom = atom<null | string>(null);
const boundariesTargetWithBoundariesAtom = atom<null | string>(null);
export const boundariesLoadMoreTargetAtom = atom<null | string>(null);
export const boundariesLoadMoreLivePricesTargetAtom = atom<null | string>(null);

export const useHydrateMapDataV4Default = () => {
    const setMapDataStateDefault = useSetAtom(mapDataV4DefaultAtom);
    const setMapDataV4DefaultLoading = useSetAtom(isMapDataV4DefaultLoadingAtom);
    const mapDataV4EntryTarget = useAtomValue(mapDataV4EntryTargetAtom);

    const component = useDynamicPageComponentByType<MapDataV4_jsonld_page_read>('MapDataV4');

    const mapBoundariesForComponents = useAtomValue(mapBoundariesForComponentsAtom);

    const addedUserQuery = {
        viewportHeight: `${window.innerHeight}`,
        viewportWidth: `${window.innerWidth}`,
        ...mapBoundariesForComponents,
    };

    const { data: mapDataInitial, isLoading: isInitialLoading } = useComponentDataByIri<MapDataV4_jsonld_page_read>(
        component?.['@id'] ?? null,
        { addedUserQuery, queryName: 'mapDataInitial' },
    );

    const { data: mapDataByEntryTarget, isLoading: isEntryTargetLoading } =
        useComponentDataByIri<MapDataV4_jsonld_page_read>(mapDataV4EntryTarget, {
            addedUserQuery,
            queryName: 'mapDataByEntryTarget',
        });

    // decide what data is currently used
    const mapDataDefault = mapDataByEntryTarget ?? mapDataInitial;
    const isDefaultLoading = isInitialLoading || isEntryTargetLoading;

    // hydrate default state atom
    React.useEffect(() => {
        if (!mapDataDefault) {
            // warning: this is not ideal but since the vertical toggle on the map changes the client url
            // it will lead to component?.['@id'] being null, and therefore mapDataInitial being null as well
            // this will lead to the map showing nothing when switching verticals
            // therefore only update the state if we have data
            return;
        }

        setMapDataStateDefault(mapDataDefault);
    }, [component, mapDataDefault, setMapDataStateDefault]);

    // update loading state
    React.useEffect(() => {
        setMapDataV4DefaultLoading(isDefaultLoading);
    }, [isDefaultLoading, setMapDataV4DefaultLoading]);
};

export const useHydrateMapDataV4ByBoundaries = () => {
    // update boundaries default data target
    const mapBoundaries = useAtomValue(mapBoundariesAtom);
    const mapDataV4Default = useAtomValue(mapDataV4DefaultAtom);
    const setBoundariesTarget = useSetAtom(boundariesTargetAtom);

    // get boundaries load more live prices target
    const boundariesLoadMoreLivePricesTarget = useAtomValue(boundariesLoadMoreLivePricesTargetAtom);
    const { data: boundariesLoadMoreLivePricesData, isLoading: isBoundariesLoadMoreLivePricesLoading } =
        useComponentDataByIri<MapDataV4_jsonld_page_read>(boundariesLoadMoreLivePricesTarget, {
            config: { staleTime: 0 },
            queryName: 'mapBoundariesLoadMoreLivePricesTarget',
        });

    React.useEffect(() => {
        setBoundariesTarget(mapDataV4Default?.meta.boundariesRequestTarget ?? null);
    }, [mapDataV4Default?.meta.boundariesRequestTarget, setBoundariesTarget]);

    // build boundaries default data request target
    const boundariesTarget = useAtomValue(boundariesTargetAtom);
    const [boundariesTargetWithBoundaries, setBoundariesTargetWithBoundaries] = useAtom(
        boundariesTargetWithBoundariesAtom,
    );
    React.useEffect(() => {
        if (isBoundariesLoadMoreLivePricesLoading) {
            // skip updating the boundaries target while load more is running
            // this way we defer the request until the load more live prices are available
            return;
        }

        if (!boundariesTarget || !mapBoundaries) {
            setBoundariesTargetWithBoundaries(null);
            return;
        }

        setBoundariesTargetWithBoundaries(
            updateComponentIriParam('userQuery', boundariesTarget, mapBoundaries).toString(),
        );
    }, [boundariesTarget, isBoundariesLoadMoreLivePricesLoading, mapBoundaries, setBoundariesTargetWithBoundaries]);

    // get boundaries default data
    const { data: boundariesData, isLoading: isBoundariesLoading } = useComponentDataByIri<MapDataV4_jsonld_page_read>(
        boundariesTargetWithBoundaries,
        { config: { staleTime: 0 }, queryName: 'mapBoundariesData' },
    );

    // get boundaries default live prices data
    const { data: boundariesLivePricesData, isLoading: isBoundariesLivePricesLoading } =
        useComponentDataByIri<MapDataV4_jsonld_page_read>(
            boundariesData?.meta.boundariesLivePricesRequestTarget ?? null,
            { config: { staleTime: 0 }, queryName: 'mapBoundariesLivePricesRequestTarget' },
        );

    // get boundaries click target, UPDATE: remove once API is fixed
    const boundariesLoadMoreTarget = useAtomValue(boundariesLoadMoreTargetAtom);
    useComponentDataByIri<MapDataV4_jsonld_page_read>(boundariesLoadMoreTarget, {
        config: { staleTime: 0 },
        queryName: 'mapBoundariesLoadMoreTarget',
    });

    // decide what boundaries data is currently used
    const mapDataByBoundaries = boundariesLoadMoreLivePricesData ?? boundariesLivePricesData ?? boundariesData;

    // reset boundaries load more live prices target once the user moves the map
    // but only once the boundaries data is loaded, otherwise we would show stale data for a moment
    const setBoundariesLoadMoreLivePricesTarget = useSetAtom(boundariesLoadMoreLivePricesTargetAtom);
    React.useEffect(() => {
        if (isBoundariesLoading || isBoundariesLivePricesLoading) {
            return;
        }
        setBoundariesLoadMoreLivePricesTarget(null);
    }, [isBoundariesLivePricesLoading, isBoundariesLoading, setBoundariesLoadMoreLivePricesTarget]);

    // hydrate boundaries state atom
    const setMapDataByBoundaries = useSetAtom(mapDataV4ByBoundariesAtom);
    React.useEffect(() => {
        setMapDataByBoundaries(mapDataByBoundaries);
    }, [mapDataByBoundaries, setMapDataByBoundaries]);

    // update boundaries loading state
    const setMapDataV4ByBoundariesLoading = useSetAtom(isMapDataV4ByBoundariesLoadingAtom);
    const isAutoLoadMoreFeatureFlagEnabled = useAutoLoadMoreSetting();
    React.useEffect(() => {
        if (isAutoLoadMoreFeatureFlagEnabled) {
            setMapDataV4ByBoundariesLoading(isBoundariesLoading || isBoundariesLivePricesLoading);
        } else {
            setMapDataV4ByBoundariesLoading(
                isBoundariesLoading || isBoundariesLivePricesLoading || isBoundariesLoadMoreLivePricesLoading,
            );
        }
    }, [
        isBoundariesLivePricesLoading,
        isBoundariesLoadMoreLivePricesLoading,
        isBoundariesLoading,
        isAutoLoadMoreFeatureFlagEnabled,
        setMapDataV4ByBoundariesLoading,
    ]);

    const setBoundariesLivePricesLoading = useSetAtom(isBoundariesLivePricesLoadingAtom);
    // update atom state with local query state for boundaries live prices loading
    React.useEffect(() => {
        setBoundariesLivePricesLoading(isBoundariesLivePricesLoading);
    }, [isBoundariesLivePricesLoading, setBoundariesLivePricesLoading]);

    // update load more loading state
    const setLoadMoreButtonLoading = useSetAtom(isLoadMoreButtonLoadingAtom);
    React.useEffect(() => {
        setLoadMoreButtonLoading(isBoundariesLoadMoreLivePricesLoading);
    }, [isBoundariesLoadMoreLivePricesLoading, setLoadMoreButtonLoading]);
};

export const useHydrateMapDataV4BySpot = () => {
    const setMapDataV4BySpotLoading = useSetAtom(isMapDataV4BySpotLoadingAtom);
    const setMapDataV4BySpot = useSetAtom(mapDataV4BySpotAtom);
    const mapDataV4EntryTarget = useAtomValue(mapDataV4EntryTargetAtom);

    const { data, isLoading } = useComponentDataByIri<MapDataV4_jsonld_page_read>(mapDataV4EntryTarget, {
        queryName: 'mapDataV4EntryTarget',
    });

    // hydrate spot data state atom
    React.useEffect(() => {
        setMapDataV4BySpot(data);
    }, [data, setMapDataV4BySpot]);

    // update loading state
    React.useEffect(() => {
        setMapDataV4BySpotLoading(isLoading);
    }, [isLoading, setMapDataV4BySpotLoading]);

    return {
        mapDataBySpot: data,
    };
};

export const useHydrateMapDataV4ByPoi = () => {
    const setMapDataByPoi = useSetAtom(mapDataV4ByPoiAtom);
    const setPoiLivePricesLoading = useSetAtom(isMapPoiLivePricesLoadingAtom);

    const mapDataByBoundaries = useAtomValue(mapDataV4ByBoundariesAtom);
    const mapDataDefault = useAtomValue(mapDataV4DefaultAtom);
    const isMapDataV4ByBoundariesLoading = useAtomValue(isMapDataV4ByBoundariesLoadingAtom);

    const poiRequestTargetWithIds = React.useMemo((): null | string => {
        const pins = mapDataByBoundaries?.attributes?.pins;
        const poiRequestTarget =
            mapDataByBoundaries?.meta.poiRequestTarget ?? mapDataDefault?.meta.poiRequestTarget ?? null;

        if (!pins || !poiRequestTarget) {
            return null;
        }

        const poiIdsString = Object.values(pins)
            .flatMap((pin) => {
                if (pin.markerType !== 'poi') {
                    return [];
                }
                return pin.poiId;
            })
            .join(',');

        const updatePoiRequestTarget = updateComponentIriParam('additionalParameters', poiRequestTarget, {
            poiId: poiIdsString,
        });

        return updatePoiRequestTarget.pathname + updatePoiRequestTarget.search;
    }, [
        mapDataByBoundaries?.attributes?.pins,
        mapDataByBoundaries?.meta.poiRequestTarget,
        mapDataDefault?.meta.poiRequestTarget,
    ]);

    const poiRequest = useComponentDataByIri<MapDataV4_jsonld_page_read>(poiRequestTargetWithIds, {
        queryName: 'mapPoiRequestTargetWithIds',
    });

    const poiLivePriceRequest = useComponentDataByIri<MapDataV4_jsonld_page_read>(
        poiRequest.data?.meta.poiLivePricesRequestTarget ?? null,
        { queryName: 'mapPoiLivePricesRequestTarget' },
    );

    const mapDataByPoi = poiLivePriceRequest.data ?? poiRequest.data;

    // hydrate poi state atom
    React.useEffect(() => {
        if (isMapDataV4ByBoundariesLoading) {
            return;
        }
        const timeout = setTimeout(() => {
            setMapDataByPoi(mapDataByPoi);
        }, 300); // skip boundaries loading states
        return () => {
            clearTimeout(timeout);
        };
    }, [isMapDataV4ByBoundariesLoading, mapDataByPoi, setMapDataByPoi]);

    // update loading state
    React.useEffect(() => {
        setPoiLivePricesLoading(poiLivePriceRequest.isLoading);
    }, [poiLivePriceRequest.isLoading, setPoiLivePricesLoading]);
};

export const useResetPoiIdInClientUrl = () => {
    const [clientUrl, setClientUrl] = useClientUrl();

    const reset = () => {
        if (!clientUrl.includes('poiId')) {
            return;
        }

        const url = getURL(clientUrl);
        url.searchParams.delete('poiId');
        historyReplaceDebounced(url.toString());
        setClientUrl(url.toString());
    };
    return reset;
};
